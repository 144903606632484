import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import Icon from "../Icon"

export const Crumbs = tw.nav`flex items-center justify-start absolute top-2-4 md:top-3-2 left-2-4 md:left-7-2 z-10`

export const Crumb = styled.p`
  ${tw`capitalize`}
  ${({ pointer }) => (pointer ? tw`cursor-pointer` : null)}
  ${({ first }) => (first ? tw`font-medium` : null)}
`

const Arrow = styled(Icon)`
  ${tw`mx-0-8`}
`

const Breadcrumb = ({ paths }) => {
  return (
    <Crumbs>
      {paths.map(({ title, url }, index) => (
        <React.Fragment key={title}>
          {index > 0 ? index === 1 ? <Arrow name={`arrow`} size={`none`} width={`20`} height={`8`} /> : <span>&nbsp;&gt;&nbsp;</span> : null}
          {url ? (
            <Crumb first={index === 0} onClick={() => navigate(url)} pointer>
              {title}
            </Crumb>
          ) : (
            <Crumb>{title}</Crumb>
          )}
        </React.Fragment>
      ))}
    </Crumbs>
  )
}

Breadcrumb.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

export default Breadcrumb
