import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link, navigate } from "gatsby"

import Image from "./Image"
import { StyledButton } from "../Styled/Button"
import { GatsbyImageSourceProps } from "../../props/ImageProps"

const Wrapper = tw.div`
  relative
`

const Desktop = styled.div`
  ${tw`absolute inset-0 hidden md:flex items-center justify-center opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto transition-all transform duration-slow`}
`

const Mask = styled.div`
  ${tw`absolute inset-0 bg-green opacity-75`}
  ${({ blog }) => (blog ? tw`cursor-pointer` : null)}
`

const Button = styled(StyledButton)`
  ${({ blog }) => (blog ? tw`text-lightest` : tw`bg-lightest text-dark`)}
  ${tw`z-10 max-w-23-2 w-full`}
`

const Mobile = tw.div`
  absolute inset-0 md:hidden flex items-center justify-center p-2-4
`

const ImageCTA = ({ mobileFixed, image, buttonText, buttonLink, alt, onClick, blog }) => {
  return (
    <Wrapper className={`group`}>
      <Image alt={alt} image={image} ratio={`11/12`} />
      {buttonLink && buttonText ? (
        <Mobile as={Link} to={buttonLink}>
          {mobileFixed && (
            <Button height={`72-56`} textSize={`16`}>
              {buttonText}
            </Button>
          )}
        </Mobile>
      ) : (
        <Mobile onClick={onClick} />
      )}
      <Desktop>
        <Mask onClick={blog ? () => navigate(buttonLink) : null} blog={blog} />
        {buttonLink ? (
          <Button className={`underlined-white`} onClick={() => navigate(buttonLink)} blog={blog} height={`72-56`} textSize={`16`}>
            <span>{buttonText}</span>
          </Button>
        ) : (
          <Button onClick={onClick} height={`72-56`} textSize={`16`}>
            {buttonText}
          </Button>
        )}
      </Desktop>
    </Wrapper>
  )
}

ImageCTA.propTypes = {
  mobileFixed: PropTypes.bool,
  alt: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(GatsbyImageSourceProps)]),
  buttonText: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  blog: PropTypes.bool,
}

ImageCTA.defaultProps = {
  mobileFixed: false,
  alt: "",
  buttonText: "Read more",
  link: "",
  onClick: () => {},
  blog: false,
}

export default ImageCTA
