import React from "react"
import { graphql } from "gatsby"

import { Blog as Page } from "../components/Blog/Blog"

export const query = graphql`
  query {
    page: sanityPageArticles {
      title
      featuredArticle {
        title
        summary
        image {
          asset {
            fluid(maxWidth: 1024) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
        attributes {
          publishedAt
        }
        _createdAt
      }
      initialAmountToShow
      increment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
    articles: allSanityArticle {
      edges {
        node {
          ...GatsbyArticleFragment
        }
      }
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
