import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Accordion from "../Accordion"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { StyledButton } from "../Styled/Button"

const Container = styled(StyledContainer)`
  ${tw`py-1-6 md:py-1-8`}
`

const FiltersWrapper = tw.div`
  -mx-0-6 flex flex-wrap justify-center
`

const FilterItem = styled(StyledButton)`
  ${tw`my-0-6 mx-0-6 max-w-10 sm:max-w-15-1 w-full`}
  ${({ active }) => (active ? tw`bg-green text-lightest` : tw`bg-light text-dark`)}
`

const Desktop = tw.div`
  hidden md:block
`

const Mobile = tw.div`
  block md:hidden
`

const BlogArticleFilters = ({ availableCategories, filters, toggleFilter }) => {
  return (
    <BackgroundContainer background={`light`}>
      <Container>
        <Desktop>
          <FiltersWrapper>
            {availableCategories?.map((filterValue, index) => (
              <FilterItem
                key={`${filterValue}-${index}`}
                onClick={() => toggleFilter(filterValue)}
                height={`56-40`}
                textSize={`16-14`}
                border
                active={filters?.includes(filterValue)}
              >
                {filterValue}
              </FilterItem>
            ))}
          </FiltersWrapper>
        </Desktop>
        <Mobile>
          <Accordion size={`blog`} title={`Tags`}>
            <FiltersWrapper>
              {availableCategories?.map((filterValue, index) => (
                <FilterItem
                  key={`${filterValue}-${index}`}
                  onClick={() => toggleFilter(filterValue)}
                  height={`56-40`}
                  textSize={`16-14`}
                  border
                  active={filters?.includes(filterValue)}
                >
                  {filterValue}
                </FilterItem>
              ))}
            </FiltersWrapper>
          </Accordion>
        </Mobile>
      </Container>
    </BackgroundContainer>
  )
}

BlogArticleFilters.propTypes = {
  availableCategories: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.arrayOf(PropTypes.string),
  toggleFilter: PropTypes.func,
}

export default BlogArticleFilters
