import React from "react"

import { useArticles } from "../../hooks/useArticles"

export const withBlog = Component => ({ name = "Blog", page, ...props }) => {
  const normalisedArticles = props.articles.edges.map(article => article.node)
  const { title, featuredArticle, initialAmountToShow, increment } = page

  const { filteredArticles, availableCategories, filters, toggleFilter, amountToShow, handleLoadMore } = useArticles(
    normalisedArticles,
    initialAmountToShow,
    increment
  )

  const articles = filteredArticles.slice(0, amountToShow)
  const showLoadMore = filteredArticles.length > amountToShow

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: `${title}`,
    },
  ]

  Component.displayName = name

  return (
    <Component
      title={title}
      paths={paths}
      featuredArticle={featuredArticle}
      filteredArticles={articles}
      availableCategories={availableCategories}
      filters={filters}
      toggleFilter={toggleFilter}
      showLoadMore={showLoadMore}
      handleLoadMore={handleLoadMore}
    />
  )
}
