import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const Container = styled(StyledContainer)`
  ${tw`pt-8 pb-3-2 md:pb-4 flex flex-col items-center justify-center text-center relative`}
`

const Title = tw.h1`
  text-38 leading-1.21 md:text-56 md:leading-1.36 font-display
`

const PageTitle = ({ background, title, children, paths, searchNoResults }) => {
  return (
    <BackgroundContainer background={background}>
      {title && (
        <Container>
          {paths && <Breadcrumb paths={paths} />}
          {!searchNoResults ? (
            <Title>{title}</Title>
          ) : (
            <Title>
              No results for <br />
              {title}
            </Title>
          )}
          {children}
        </Container>
      )}
    </BackgroundContainer>
  )
}

PageTitle.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  searchNoResults: PropTypes.bool,
}

PageTitle.defaultProps = {
  background: `light`,
  searchNoResults: false,
}

export default PageTitle
