import { useState, useEffect } from "react"

export const useArticles = (articles, initialAmountToShow, increment) => {
  const [filteredArticles, setFilteredArticles] = useState(articles)
  const [filters, setFilters] = useState([])
  const [amountToShow, setAmountToShow] = useState(initialAmountToShow)

  useEffect(() => {
    const filteredArticles = filters.length
      ? articles.filter(article => [...new Set(article.categories.filter(category => filters.includes(category?.slug?.current)))].length)
      : articles
    setFilteredArticles(filteredArticles)
  }, [filters])

  const toggleFilter = value => {
    filters.includes(value) ? setFilters(filters => filters.filter(item => item !== value)) : setFilters(filters => [...filters, value])
  }

  const availableCategories = []
  articles.forEach(article =>
    article.categories.forEach(category => {
      if (!availableCategories.includes(category?.slug?.current)) {
        availableCategories.push(category?.slug?.current)
      }
      return null
    })
  )

  const handleLoadMore = () => {
    setAmountToShow(prevState => prevState + increment)
  }

  return {
    filteredArticles,
    availableCategories: availableCategories.sort(),
    filters,
    toggleFilter,
    amountToShow,
    handleLoadMore,
  }
}
