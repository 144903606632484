import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withBlogListingCard } from "./withBlogListingCard"
import ImageCTA from "../Image/ImageCTA"

const Wrapper = tw.div`
  flex flex-col w-1/2 lg:w-1/4 p-0-8 sm:p-1-6
`

const ImageWrapper = tw.div`w-full`

const ContentWrapper = tw.div`flex-1 flex flex-col`

const Content = tw.div`flex-1`

const Title = styled.p`
  ${tw`text-16 sm:text-22 md:text-32 leading-1.36 md:leading-1.31 block md:font-medium font-display my-0-8 md:my-1-6`}
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
`

const Date = tw.p`text-12 md:text-14 opacity-75 uppercase mt-1-6 md:mt-2-4`

const BlogListingCard = withBlogListingCard(({ title, image, link, date }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <ImageCTA image={image.src} buttonText={`Read more`} buttonLink={link} alt={image.alt} blog={true} />
      </ImageWrapper>
      <ContentWrapper>
        <Content>
          {title && (
            <Link to={link}>
              <Title>{title}</Title>
            </Link>
          )}
        </Content>
        {date && <Date>{date}</Date>}
      </ContentWrapper>
    </Wrapper>
  )
})

export const BlogListingCardPropTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  link: PropTypes.string,
  publishedAt: PropTypes.string,
}

BlogListingCard.propTypes = BlogListingCardPropTypes

export default BlogListingCard
