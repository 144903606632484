import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withBlog } from "./withBlog"
import PageTitle from "../PageTitle/PageTitle"
import BlogFeaturedArticle from "./BlogFeaturedArticle"
import BlogArticleFilters from "./BlogArticleFilters"
import BlogListing from "./BlogListing"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { StyledButton } from "../Styled/Button"

const Container = styled(StyledContainer)`
  ${tw`pt-4-8 pb-6-4 md:pt-8 md:pb-8-8 flex items-center justify-center`}
`

const LoadMore = styled(StyledButton)`
  ${tw`max-w-20-8 md:max-w-25 w-full`}
`

export const Blog = withBlog(
  ({ title, paths, featuredArticle, availableCategories, filters, toggleFilter, filteredArticles, showLoadMore, handleLoadMore }) => {
    return (
      <>
        <PageTitle title={title} paths={paths} />
        <BlogFeaturedArticle featuredArticle={featuredArticle} />
        <BlogArticleFilters availableCategories={availableCategories} filters={filters} toggleFilter={toggleFilter} />
        <BlogListing articles={filteredArticles} />
        <BackgroundContainer background={`light`}>
          <Container>
            {showLoadMore && (
              <LoadMore height={`56`} textSize={`16`} colour={`light`} border onClick={handleLoadMore}>
                Load more
              </LoadMore>
            )}
          </Container>
        </BackgroundContainer>
      </>
    )
  }
)
