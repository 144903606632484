import React from "react"

export const withBlogFeaturedArticle = Component => ({ name = "BlogFeaturedArticle", featuredArticle, ...props }) => {
  const image = featuredArticle?.image?.asset?.fluid
  const { title, summary } = featuredArticle
  const date = featuredArticle?.attributes?.publishedAt
    ? new Date(featuredArticle?.attributes?.publishedAt).toLocaleDateString()
    : new Date(featuredArticle?._createdAt).toLocaleDateString()
  const link = featuredArticle?.slug?.current ? `/blog/${featuredArticle?.slug?.current}` : "/"

  Component.displayName = name
  return <Component image={image} title={title} description={summary} date={date} link={link} />
}
