import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import BlogListingCard, { BlogListingCardPropTypes } from "./BlogListingCard"
import { BackgroundContainer } from "../Styled/Container"

const Container = tw.div`
  w-full max-w-full py-0-8 md:py-2-4 max-w-xl mx-auto px-1-2 sm:px-3-6 md:px-7-2
`

const Wrapper = tw.div`
  flex flex-wrap md:-mx-1-6
`

const BlogListing = ({ articles }) => {
  return (
    <BackgroundContainer background={`light`}>
      {articles && (
        <Container>
          <Wrapper>
            {articles.map((article, index) => (
              <BlogListingCard key={index} article={article} />
            ))}
          </Wrapper>
        </Container>
      )}
    </BackgroundContainer>
  )
}

BlogListing.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape(BlogListingCardPropTypes)),
}

export default BlogListing
