import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withBlogFeaturedArticle } from "./withBlogFeaturedArticle"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import ImageContent from "../ImageContent"
import { GatsbyImageSourceProps } from "../../props/ImageProps"

const Container = styled(StyledContainer)`
  ${tw`pt-0-8 pb-2-4 md:pt-2-4`}
`

const BgWrapper = tw.div`
  bg-lightest h-full
`

const ContentWrapper = tw.div`
  h-full max-w-45-6 mx-auto flex flex-col items-start justify-around pt-3-2 pb-4 px-4
`

const Content = styled(Link)`
  ${tw`mb-2-4`}
`

const Title = tw.h2`
  text-28 leading-1.29 md:text-44 leading-1.45 mb-1-6 md:mb-2-4 font-display
`

const Description = tw.p`
  text-16 leading-1.75 opacity-75
`

const Date = tw.p`
  text-14 opacity-75 uppercase
`

const BlogFeaturedArticle = withBlogFeaturedArticle(({ image, title, description, date, link }) => {
  return (
    <BackgroundContainer background={`light`}>
      <Container>
        <ImageContent image={image}>
          <BgWrapper>
            <ContentWrapper>
              <Content to={link}>
                <Title>{title}</Title>
                <Description>{description}</Description>
              </Content>
              {date && <Date>{date}</Date>}
            </ContentWrapper>
          </BgWrapper>
        </ImageContent>
      </Container>
    </BackgroundContainer>
  )
})

BlogFeaturedArticle.propTypes = {
  image: PropTypes.shape(GatsbyImageSourceProps),
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  link: PropTypes.string,
}

export default BlogFeaturedArticle
