import React from "react"

import { useApp } from "../../hooks/useApp"

export const withBlogListingCard = Component => ({ name = "BlogListingCard", article, ...props }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const image = {
    src: article?.image?.asset?.url,
    alt: article?.image?.alt,
  }
  const { title } = article
  const date = article?.attributes?.publishedAt
    ? new Date(article?.attributes?.publishedAt).toLocaleDateString()
    : new Date(article?._createdAt).toLocaleDateString()
  const link = article?.slug?.current ? `${routes[`ARTICLES`]}/${article?.slug?.current}` : "/"

  Component.displayName = name
  return <Component image={image} title={title} date={date} link={link} />
}
